.view-tabcontents(@bgInActiveColor, @linkColor, @displayFirstTab) {
  overflow: hidden;

  .btn {
    cursor: default;
    background-color: #FFF;
    border-radius: 4px;
    text-align: left;
  }

  .btn-group {width: 100%}
  .dropdown-menu {width: 100%;}

  .custom-dropdown {
    background-color: @white;

    &:hover {
      background-color: @white;      
    }
  }

  .custom-dropdown-ul {
    color: @gray-dark;
  }

  ul.nav-tabs {
    .make-row();
    margin-left: 0px;
    margin-right: 0px;

    li {
      .make-xs-column(12);
      .make-sm-column(4);
      padding-left: 0px;
      padding-right: 0px;


      &:first-child {
        display: @displayFirstTab;
      }

      @media @tablet {
        text-align: center;
      }
    }
  }

  .nav-tabs {
    border-bottom: 0px;
    margin-bottom: 20px;

    @media @tablet {
      margin-bottom: 36px;
    }

    > li {
      > a, > a:focus {
        border: 1px solid @gray4;
        background-color: @bgInActiveColor;
        border-radius: 0px;
        border-bottom-color: @gray4;
        color: @linkColor;
        font-size: 16px;
        margin-right: 0px;
        outline: none !important;
        padding: 10px 15px;
        padding-bottom: 13px;
        padding-top: 13px;
        position: relative;
        text-decoration: none;

        &:focus, &::-moz-focus-inner {
          outline: none !important;
        }

        @media @tablet {
          border: 1px solid transparent;
          border-bottom-color: @gray4;
          border-left: 0px;
          border-right: 0px;

          &:after {
            background-color: @gray1;
            content: "";
            display: block;
            position: absolute;
            height: 30px;
            overflow: hidden;
            right: -1px;
            top: 10px;
            width: 1px;
            z-index: 2;
          }
        }

        &:hover {
          background-color: transparent;
          border: 1px solid @gray4;
          z-index: 3;

          @media @tablet {
            border-bottom-color: transparent;
          }

          &:after {
            display: none;
          }
        }
      }

      &.active > a {
        background-color: transparent;
        border: 1px solid @gray4;
        z-index: 3;
        
        @media @tablet {
          border-bottom-color: transparent;
        }

        &:hover {
          background-color: transparent;
          color: @linkColor;
        }

        &:after {
          border: 1px solid @gray4;
          display: none;
        }
      }
    }
  }

  .tab-content {
    margin-bottom: 20px;
    margin-top: 30px;

    @media @tablet {
      margin-top: 0px;
    }
  }
}

.section-background-bright {
  .view-tabcontents(@white, @gray2, none);

  .nav-tabs > li {
    @media @tablet {
      &:nth-of-type(2) > a {
        border-radius: 4px 0px 0px 0px;
      }

      &:nth-of-type(3) > a {
        border-radius: 0px;
      }

      &:nth-of-type(4) > a {
        border-radius: 0px 4px 0px 0px;

        &:after {
          display: none;
        }
      }
    }
  }
}

.section-background-dark {
  .view-tabcontents(@gray3, @white, block);

  .nav-tabs > li {
    @media @tablet {
      &:nth-of-type(1) > a {
        border-radius: 4px 0px 0px 0px;
      }

      &:nth-of-type(2) > a {
        border-radius: 0px;
      }

      &:nth-of-type(3) > a {
        border-radius: 0px 4px 0px 0px;

        &:after {
          display: none;
        }
      }
    }
  }
}