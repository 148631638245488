header#navbar {
  margin-bottom: 0px;
}

body.navbar-is-fixed-top {
  padding-top: 0px !important;
}

.region-content {
  margin-top: 50px;

	@media @normal
	{
		margin-top: 70px;
	}
}

.navbar-default {
  background-color: transparent;
  border: 0px;
}

form#user-login {
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    border: 1px solid #000!important;
  }
}

.footer {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 0px;

  &>.container {
    &:after {
      overflow: hidden;
    }

    :after {
      overflow: hidden;
    }
  }
}

.node-insider-report.view-mode-teaser {
  blockquote {
    padding: 0;
    margin: 25px 0px 0px 0px;
    font-size: inherit;
    border-left: 0px;
  }
}

p:last-child {
  margin-bottom: 15px;
}

p:last-child.marginbottom {
	margin-bottom: 24px;

	@media @tablet
	{
		margin-bottom: 30px;
	}
}

.view-mode-iframe_content {
  .paragraphs-item-text-only {
    &>.row {
      &>.col-sm-12 {
        padding-left:0;
        padding-right:0;
      }
    }
  }
}

#schnelltest {
  .radiooverlay, .radiotext {
    cursor: pointer;
  }
}

.bgimage {
  img {
    top:0px !important;
  }
}

// backend login error
div.error {
  background-repeat: no-repeat;
}

a.btn {
  outline: none !important;

  &:focus, &::-moz-focus-inner {
    border: 0;
    outline: none !important;
  }
}

.btn-inactive {
  display: none;
}

#section-38 {
  @media (max-width: @screen-xs-max) {
    padding-bottom: 60px;

    .custom-dropdown {
      padding-right: 42px;
    }
  }

  .container > .row > .col-sm-12 {
    .make-sm-column-offset(2);
    .make-sm-column(8);
  }
}

#panel-bootstrap-column-1 {
  .make-sm-column-offset(3);
}
