.view-display-id-block {

  padding-top: 10px;

  @media @tablet {
    padding-top: 20px;
  }

  .owl-carousel {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media @normal {
    padding-left: 0;
    padding-right: 0;
  }

  .owl-item {

    .col-sm-6 {
      padding-left: 23px;
      padding-right: 23px;

      @media @tablet {
        padding-left: 30px;
        padding-right: 30px;
      }

      @media @normal {
        padding-left: 15px;
        padding-right: 15px;
      }

    }

    .node-blog-article {

      .field-name-field-date {
        color: @gray4;
        margin-top: 20px;

        @media @tablet {
          margin-top: 0;
        }
      }

      .field-name-title {

        h2 {
          font-weight: 300;
          margin-top: 10px;
          margin-bottom: 5px;
        }
      }

      .field-name-field-blog-category {
        margin-bottom: 15px;
        font-weight: 700;
        color: @gray4;
        text-transform: uppercase;
        font-size: 14px;
      }

      .field-name-node-link {
        text-align: right;

        a {
          .btn;
          color: @red;
          text-decoration: none;

          &:before {
            content: '\f101';
            font-family: 'FontAwesome';
            margin-right: 8px;
            font-size: 16px;
          }

          &:hover, &:focus {
            color: @red;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .owl-controls {
    top: -40px;
    left: 0;
    right: 0;
    margin-left: 0;

    @media @tablet {
      top: 0;
      left: auto;
      right: auto;
    }

    margin-top: 5px;
    margin-bottom: 25px;

    @media @tablet {
      padding-left: 15px;
      text-align: center;
    }

    @media @normal {
      padding-left: 0;
      text-align: inherit;
    }

    .owl-dot {

      span {
        background-color: @white;
      }
    }

    .owl-dot.active {

      span {
        background-color: @red;
      }
    }
  }

  .view-footer {
    text-align: center;

    a {
      .btn;
      background-color: @dark;
      color: @white;
      text-decoration: none;

      &:hover, &:focus {
        color: @white;
        background-color: @medium;
      }
    }
  }
  
  &.view-blog {
    position: relative;
    z-index: 1;
    
    .owl-stage-outer {
      @media @tablet {
        margin-bottom: 0px;
        margin-top: 30px;
      }
      
      @media @normal {
        margin-top: 0px;
      }
    }
    
    .owl-controls {
      top: -40px;
      left: 0;
      right: 0;
      margin-left: 0;
      position: absolute;
      
      @media @tablet {
        top: -50px;
      }
    
      @media @normal {
        top: 0;
        left: auto;
        right: auto;
        position: relative;
      }
    }
  }
  
}

body:not(.node-type-blog-article):not(.blog_overview) {

  [data-hash="blog"] {

    .owlarrows {
      z-index: 9;

      .owl-next, .owl-prev {
        display: block !important;
        color: @white;
      }

      .owl-next:last-of-type {
        display: none !important;
      }

    }

    &.section-background-default {

      .owlarrows {
        .owl-next, .owl-prev {
          color: @light;
        }
      }
    }

    .owl-controls {

      .owl-dot {

        span {
          background-color: @light;
        }

        &.active {

          span {
            background-color: @red;
          }
        }
      }
    }


  }
}
