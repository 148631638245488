.view-faqs {
  .views-field-body, .views-field-field-video {
    display: none;
  }

  .btn {
    cursor: default;
    background-color: #FFF;
    border-radius: 4px;
    text-align: left;
  }

  .btn-group {width: 100%}
  .dropdown-menu {width: 100%;}

  .custom-dropdown {
    background-color: @gray1;
  }

  .faq-result-container {
    padding-top: 20px;

    @media @tablet {
      padding-top: 38px;
    }
  }

  .faq-result-label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    @media @tablet {
      margin-bottom: 20px;
    }
  }

  .faq-result-content {
    text-align: center;

    .views-field-title {
      display: none;
    }

    .views-field-body, .views-field-field-video {
      display: block;
    }

    .views-field-field-video, .video {
      .box-shadow(0px 10px 20px 0px rgba(0,0,0,0.75));
    }

    // http://stackoverflow.com/a/12235638
    .views-field-field-video .field-content, .video .field-content {
      position: relative;
      padding-top: 25px;
      padding-bottom: 67.5%;
      height: 0;
      margin-bottom: 60px;
      overflow: hidden;
    }

    .views-field-field-video .field-content,
    .video .field-content {
      padding-bottom: 57.25%;
      padding-top: 0px;
    }

    .views-field-field-video .field-content iframe,
    .views-field-field-video .field-content object,
    .views-field-field-video .field-content embed,
    .video .field-content iframe,
    .video .field-content object,
    .video .field-content embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.section-background-bright {

  .view-faqs {

    .custom-dropdown {
      background-color: @white;

    }
  }
}