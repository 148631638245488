.node-section.section-label {
    padding-top: 5px;
    padding-bottom: 0;
    margin: 0;
    overflow: visible;

    .collapse-trigger {
        position: relative;

        .field-name-title {
            display: block;
            position: absolute;
            text-align: center;
            bottom: 0;
            width: 100%;

            h2 {
                .btn();
                .btn-default();
                .btn-invert.btn-default();

                font-size: 16px;
                line-height: 40px;

                &, &:hover {
                    background: @gray-dark;
                    color: @white;
                }
            }
        }

        &[aria-expanded="true"] {
            .field-name-title {
                h2 {
                    color: transparent;
                    position: relative;

                    &:before {
                        content: "\f00d";
                        font: normal normal normal 14px/1 FontAwesome;
                        color: @white;
                        font-size: 20px;
                        line-height: 40px;

                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                }
            }
        }

    }

    .collapse-content .container-fluid {
        padding-bottom: 30px;
        padding-top: 30px;

        @media @tablet {
            padding-bottom: 64px;
            padding-top: 50px;
        }
    }
}
