.blog-exposed-filter {
  background-color: @gray5;
  margin: 0px -(@grid-gutter-width/2);
  
  &.affix {
    position: relative;
  }
  
  @media @tablet {
    background-color: transparent;
    top: 125px;
    width: 100%;
  
    &.affix {
      max-width: inherit;
      position: fixed;
      width: inherit;
    }
    
    &.affix-top {
      margin: 0px;
    }
  }
  
  &:before {
    content: "";
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2;
    
    @media @tablet {
      position: relative;
    }
  }

  .blog-exposed-filter-inner {
    padding: 40px (@grid-gutter-width/2) 40px;
    position: relative;
    z-index: 3;
    
    @media @tablet {
      background-color: @gray1;
      padding: 10px (@grid-gutter-width/2) 0px;
    }

    a, a:focus, a:hover {
      color: @red;
      display: block;
      font-family: @font-family-sans-serif-brand;
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 10px;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }

      &.active {
        font-weight: bold;
      }
    }
  }
  
  @media @tablet {
    &.affix {
      .blog-exposed-filter-inner {
        margin: 0px (@grid-gutter-width/2);
      }
    }
  }

  .exposed-title {
    border-bottom: 1px solid @gray4;
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 20px;
    padding-bottom: 6px;
  }

  .views-exposed-widgets {
    margin: 0px;
  }

  .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0px;
    width: 100%;
  }
}

.node-blog-article.view-mode-teaseroverview {
  margin-bottom: 40px;

  @media @tablet {
    margin-bottom: 54px;
  }

  .field-name-field-date {
    color: @gray4;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .field-name-title {
    h2, .h2 {
      font-size: 30px;
      font-weight: 200;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }

  .field-name-field-blog-category {
    color: @gray4;
    font-weight: bold;
    margin-bottom: 26px;
    text-transform: uppercase;
  }

  .field-name-field-image-wide {
    margin-bottom: 16px;

    img {
      max-width: none;
      width: 100%;
    }
  }

  .field-name-body {
    margin-bottom: 8px;
    p {
      margin: 0px;
    }
  }

  .read-more {
    text-align: right;

    a, a:focus, a:hover {
      color: @red;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: "\f101";
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 16px;
        padding-right: 4px;
      }
    }
  }
}

.view-blog.view-display-id-block_2 {

  .view .progress-disabled {
    float: left;
  }

  .views-row-last {
    margin-bottom: 40px;

    .node-blog-article.view-mode-teaseroverview {
      margin-bottom: 52px;

      @media @tablet {
        margin-bottom: 58px;
      }
    }
  }

  .pagination {
    font-size: 0;
    margin: 0px 0 60px;
    text-align: center;
    width: 100%;

    @media @tablet {
      text-align: left;
      width: auto;
    }

    div.ajax-progress-throbber {
      display: none;
    }
  }

  .pagination > li {
    height: 40px;
    overflow: hidden;
    width: 40px;

    > a, > a:focus, > a:hover, > span {
      border: 0px;
      border-radius: 4px;
      color: @text-color;
      display: inline-block;
      float: none;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      margin: 0px;
      overflow: hidden;
      padding: 0px;
      position: relative;
      text-align: center;
      text-decoration: none;
      width: 40px;
      
      @media @tablet {
        float: left;
      }
    }

    &.active {
      > a, > a:focus, > a:hover {
        background-color: transparent;
        color: @red;

        &:after {
          background-color: @red;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          margin-left: -5px;
          position: absolute;
          top: 28px;
          width: 10px;
        }
      }
    }
  }

  .pagination > li.prev, .pagination > li.next {
    .box-shadow(none);
    position: absolute;
    
    @media @tablet {
      position: relative;
    }

    a, a:focus, a:hover, span {
      background-color: @gray6;
      color: @white;
      display: block;
      text-indent: 60px;
      text-decoration: none;
  
      &:hover {
        background-color: @medium;
      }

      &:before {
        display: block;
        font-family: FontAwesome;
        height: 40px;
        left: 0px;
        line-height: 40px;
        position: absolute;
        text-indent: 0px;
        width: 40px;
      }
    }
  }

  .pagination > li.prev {
    left: (@grid-gutter-width/2);
    
    @media @tablet {
      left: auto;
    }
    
    a, a:focus, a:hover {
      &:before {
        content: "\f104";
      }
    }
  }

  .pagination > li.next {
    right: (@grid-gutter-width/2);
  
    @media @tablet {
      right: auto;
    }
    
    a, a:focus, a:hover {
      &:before {
        content: "\f105";
      }
    }

    @media @tablet {
      float: left;
    }
  }

}

body.blog_overview {
  .breadcrumb {
    display: none;
  }

  section.node-section:nth-of-type(1) {
    > .container {
      padding: 0px;

      > .row {
        margin: 0px;
        position: relative;

        > .owlarrows {
          display: none;
        }

        > .col-sm-12 {
          padding: 0px;
        }
      }
    }

    .owl-item > div {
      padding-left: (@grid-gutter-width/2);
      padding-right: (@grid-gutter-width/2);
    }

    .owl-carousel .owl-item img {
      -webkit-transform-style: flat !important;
      -moz-transform-style: flat !important;
      -ms-transform-style: flat !important;
      transform-style: flat !important;
    }

    .view-mode-teaserslider {
      background-color: @gray6;
      border-radius: 4px;
      color: @white;

      @media @tablet {
        position: relative;
      }
    }

    .owl-controls {
      position: relative;
      text-align: left;

      @media @tablet {
        padding-left: (@grid-gutter-width/2);
        top: 0px;
      }

      .owl-dot {
        span {
          background: @white;
        }

        &.active span {
          background: @red;
        }
      }
    }

    .owl-stage-outer {
      margin-bottom: 18px;
      margin-top: 0px;
      padding: 0px;
    }

    .owlarrows {
      top: 108px;

      @media @tablet {
        top: 170px;
        z-index: 3;
      }

      @media @normal {
        top: 203px;
      }

      > div {
        background-color: @white;
        border-radius: 3px;
        color: @text-color;
        height: 45px;
        opacity: 0.5;
        text-align: center;
        width: 45px;
        
        &:hover {
          opacity: 1;
        }

        .fa {
          display: block;
          font-size: 25px;
          height: 45px;
          line-height: 45px;
          width: 45px;
        }
      }

      .owl-prev {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        left: (@grid-gutter-width/2);
      }

      .owl-next {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        right: (@grid-gutter-width/2);
      }
    }

    h1, .h1 {
      font-size: 45px;
      line-height: 47px;
      padding-left: (@grid-gutter-width/2);
      padding-right: (@grid-gutter-width/2);
      text-transform: uppercase;

      @media @tablet {
        margin-bottom: 54px;
      }
    }

    h2, .h2 {
      color: @white;
      margin-bottom: 0px;

      @media @tablet {
        font-size: 30px;
        font-weight: bold;
        line-height: 36px;
      }
    }

    .field-name-field-image-wide {
      margin-bottom: 16px;

      @media @tablet {
        margin-bottom: 0px;
        min-height: 300px;

        img {
          border-radius: 4px;
        }
      }
      
      @media @wide {
        min-height: 508px;
      }
    }

    .field-name-field-date {
      font-family: @font-family-sans-serif-brand;
      font-size: 14px;
      font-weight: bold;
      padding: 0px 38px 4px;

      @media @tablet {
        padding: 0px 170px 4px 30px;
        position: relative;
        z-index: 3;
      }
    }

    .field-name-title {
      padding: 0px 38px 4px;

      @media @tablet {
        padding: 0px 170px 4px 30px;
        position: relative;
        z-index: 3;
      }
    }

    .field-name-field-blog-category {
      font-family: @font-family-sans-serif-brand;
      font-size: 14px;
      font-weight: bold;
      padding: 0px 38px 18px;
      text-transform: uppercase;

      @media @tablet {
        padding: 0px 170px 0px 30px;
        position: relative;
        z-index: 3;
      }
    }

    .read-more {
      padding: 0px 30px 30px;
      text-align: right;

      @media @tablet {
        bottom: 0px;
        position: absolute;
        right: 0px;
        z-index: 3;
      }

      a {
        .btn;
        .btn-default;
        .btn-white;
        background-color: @white;
        color: @gray6;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;

        &:focus {
          background-color: @white;
          color: @gray6;
        }
      }
    }

    .text-content {
      @media @tablet {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: 0px;
        padding-bottom: 22px;
        position: absolute;
        width: 100%;

        &:before {
          .gradient-transparent-black-vertical;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          bottom: 0px;
          content: "";
          display: block;
          min-height: 238px;
          position: absolute;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }

  section.node-section:nth-of-type(2) {
    overflow: hidden;
    padding-bottom: 0px;
  }
  
  .view-display-id-block_2 {
    > .row > .col-sm-4 {
      @media @tablet {
        max-width: 250px;
      }
      
      @media @normal {
        max-width: 323px;
      }
    }
  }
}

.view-blog.view-display-id-block_1 {
  > .view-content {
    position: relative;
  }
}

// main-menu: set blog active on blog article pages and overview
body.node-type-blog-article, body.blog_overview {
  #main-menu-navigation > ul > li:nth-of-type(2) {
    border-bottom: 4px solid #e60000;
    
    &:hover {
      border-bottom: 4px solid #e60000;
    }
  }
}