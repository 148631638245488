#sliding-popup {
  top: 0;
  position: fixed;
  background-color: @gray5;
  width: 100%;
  
  @media (max-width: 714px) {
    height: 177px !important;
    
    #popup-text {
      width: 100%;
      max-width: 100% !important;
      
      p {
        margin-bottom: 0px;
      }
    }
    
    #popup-buttons {
      width: 100%;
      max-width: 100% !important;
    }
  }
  
  .popup-content {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    
    @media @wide {
      .container;
    }
    
    #popup-text {
      color: @text-color;
      
      @media @wide {
        max-width: 80%;
      }
    }
    
    #popup-buttons {
      
      button {
        color: @red;
        padding: 0;
        background: none;
        border: 0 none transparent;
        margin: 0;
        box-shadow: none;
        text-shadow: none;
        margin: 4px 10px;
        
        &:before {
          content: '\f105';
          font-family: 'FontAwesome';
          margin-right: 5px;
        }
        
        &:hover {
          box-shadow: none;
          background: none;
          text-decoration: underline;
        }
      }
    }
  }
}

.maincontainer.cookie-not-accepted {
  
  #navbar {
    
    @media (max-width: 714px) {
      margin-top: 177px;
    }
    
    margin-top: 124px;
    
    @media (min-width: 933px) {
      margin-top: 102px;
    }
    
    @media @wide {
      margin-top: 80px;
    }
  }
  
  .main-container {
    @media (max-width: 714px) {
      margin-top: 227px;
    }
    
    margin-top: 104px;
    
    @media (min-width: 933px) {
      margin-top: 80px;
    }
    
    @media @normal {
      margin-top: 100px;
    }
  }
}