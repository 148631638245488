// IE FIX to disable zoom and double tap
html {
    -ms-content-zooming: none;
    -ms-touch-action: pan-x pan-y;
}

// Insert your styling here.

// ************************
// *** SECTIONS
// ************************
.section-background-default
{
    background-color: #fff !important;
}

.section-background-bright
{
    background-color: @gray1 !important;
}

.section-background-dark
{
    background-color: @gray-dark !important;
    color: #ffffff;
}

.section-background-image
{
    color: @text-color;
    background-color: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

    @media (max-width: @screen-xs-max)
    {
        background-image: none !important;
    }

    @media @normal
    {
        background-color: transparent !important;
    }
}

.section-background-default,
.section-background-bright,
.section-background-dark
{
    background-image: none !important;
}

// .view-mode-iframe_content
// {
//  > .container-fluid
//  {
//      padding-left: 0px !important;
//      padding-right: 0px !important;
//  }
// }


// ************************
// *** OWL ARROWS start ***
// ************************
.owlarrows
{
    &.affix,
    &.affix-top,
    &.affix-bottom
    {
        position: absolute;
        z-index: 1;
    }
}

// ***************************
// *** GOOGLE iFrame start ***
// ***************************


iframe[name="google_conversion_frame"]
{
  position:absolute;
}