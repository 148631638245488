.node-type-blog-article, body.blog_overview {
  .header {

    &:before {
      background: url(../images/vodafone-logo-white.svg) no-repeat 0px 0px;
      background-size: 40px 40px;
      content: "";
      display: block;
      height: 76px;
      left: 10px;
      top: 5px;
      position: absolute;
      width: 122px;
      z-index: 11;
    }

    @media @normal {
      &:before {
        display: none;
      }

      .blog-header {
        background-image: url("../images/logo_vodafone.jpg");
        background-position: right top;
        background-size: cover;
        height: 70px;
        width: 325px;
        background-repeat: no-repeat;
        z-index: 1;
        position: absolute;
      }
    }

    > .container {

      @media @tablet {
        &:after {
          display: none;
        }
      }

      @media @normal {
        &:after {
          background: url(../images/vodafone-logo-white.svg) no-repeat right top;
          background-size: 50px 50px;
          content: " ";
          display: block;
          height: 70px;
          margin-left: -100%;
          position: absolute;
          left: 64px;
          top: 10px;
          visibility: visible;
          width: 100%;
        }
      }
    }

    @media screen and (min-width: 1230px) {
      .blog-header {
        background-image: url("../images/arrow-blog.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 315px;
        height: 360px;
        background-position: left top;
        z-index: 1;
        position: absolute;
      }
    }

    &.scrolled {

      .logo_vodafone {
        display: inline-block;
      }

      @media screen and (min-width: 1230px) {

        .blog-header {
          background-image: url("../images/logo_vodafone.jpg");
          background-position: right top;
          height: 70px;
          width: 325px;
        }
      }
    }
  }
}

.node-type-blog-article {
  .main-container {
    padding-top: 20px;

    @media @normal {
      padding-top: 45px;
    }

    .blog-title {
      text-transform: uppercase;
      font-size: 45px;

      @media @tablet {
        margin-bottom: 30px;
      }

      @media @normal {
        margin-bottom: 45px;
      }
    }

    .node-blog-article {

      .field-name-field-date {
        color: @text-color;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .field-name-title {
        h2, .h2 {
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 5px;
          text-align: left;
        }
      }

      .field-name-field-blog-category {
        color: @gray4;
        font-weight: 700;
        text-transform: uppercase;
      }

      .field-name-field-image {
        margin-top: 25px;
        margin-bottom: 15px;

        img {
          max-width: none;
          width: 100%;
          height: auto;
        }
      }

      .field-name-body {

        p {
          font-weight: 300;
        }

        a {
          font-weight: 700;
          color: @red;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .field-name-field-paragraphs {
        margin-top: 30px;

        @media @normal {
          margin-top: 50px;
        }

        .entity-paragraphs-item {
          margin-bottom: 20px;

          @media @normal {
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }

      .links {
        margin-top: 55px;
        margin-bottom: 85px;

        .back-to-overview {
          margin-bottom: 20px;

          a {
            .btn;
            background-color: @text-color;
            color: @white;
            text-decoration: none;

            &:hover, &:focus {
              background-color: @dark;
              color: @white;
              text-decoration: none;
            }
          }
        }

        .netiquette {

          a {
            color: @red;
            text-decoration: none;
            font-size: 12px;

            &:before {
              content: '\f101';
              font-family: 'FontAwesome';
              margin-right: 8px;
            }

            &:hover, &focus {
              color: darken(@red, 15%);
              text-decoration: underline;
            }
          }
        }
      }
    }
    .group-affix-wrapper {

      margin-bottom: 45px;

      &.affix {

        @media @tablet {
          width: 220px;
          top: 85px;
        }

        @media @normal {
          width: 293.33px;
          top: 90px;
        }

        @media @wide {
          width: 293.33px;
        }
      }

      .field-name-shariff-block {
        padding: 15px 20px 20px;
        background-color: @gray5;

        .label-above {
          font-weight: 700;
          margin-bottom: 20px;
        }

        .shariff-button {
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .field-name-more-articles {
        padding: 15px 20px 20px;
        background-color: @gray5;
        margin-top: 20px;

        .block-title {
          display: none;
        }

        .label-above {
          .h3;
          margin: 0;
          font-weight: 700;
        }

        .field-name-field-image {
          margin-top: 15px;
          margin-bottom: 5px;

          img {
            max-width: none;
            width: 100%;
            height: auto;
          }
        }

        .field-name-field-date {
          margin-bottom: 0;
        }

        .field-name-title {

          a {
            color: @red;
            text-decoration: none;

            &:hover, &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .maincontainer.cookie-not-accepted {

    .main-container {

      .group-affix-wrapper {

        &.affix {

          @media @tablet {
            width: 220px;
            top: 119px;
          }

          @media (max-width: 933px) {
            top: 175px;
          }

          @media @normal {
            width: 293.33px;
            top: 125px;
          }

          @media @wide {
            width: 293.33px;
          }
        }
      }
    }
  }
}
