.checklist {
  list-style: none;
  margin-bottom: 15px;
  margin-left: 0px;
  padding-left: 0px;

  @media @tablet {
    margin-bottom: 0px;
  }

  li {
    list-style: none;
    margin-left: 0px;
    padding-bottom: 10px;
    padding-left: 32px;
    position: relative;

    &:before {
      color: @red;
      content: '\f00c';
      display: block;
      font-family: 'FontAwesome';
      font-size: 18px;
      left: 0px;
      position: absolute;
      top: 0px;
    }
  }
}

.highlighted-text {
  font-size: 16px;
  color: @red;

  a {
    color: @red;
  }
}