html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  outline: 0;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    font-smooth: always;

    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
  color: @text-color;
    .font-size-line-height-p();

  background: #fff;
}

a {
    color: @gray-dark;
    text-decoration: underline;

    &:hover {
        color: @gray-dark;
        text-decoration: none;
    }

    &.btn {
        text-decoration: none;
    }
}

h1 { .typoTag("h1"); }
h2, .h2 { .typoTag("h2"); }
h3 { .typoTag("h3"); }
h4 { .typoTag("h4"); }
h5 { .typoTag("h5"); }
h6 { .typoTag("h6"); }

p { .typoTag("p"); }
p {
  line-height: @line-height-p-normal;
}

h1 {
  text-align: center;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;

  @media @tablet {
    margin-bottom: 30px;
  }
}

h2, .h2 {
  margin-bottom: 16px;

  @media @tablet {
    margin-bottom: 10px;
  }

  @media @normal {
    margin-bottom: 24px;
  }
}

h3 {
  margin-bottom: 10px;

  @media @normal {
    margin-bottom: 20px;
  }
}

h4 {
  margin-bottom: 10px;
}

h5 {
  margin-bottom: 10px;
}

h6 {
  margin-bottom: 10px;
}

p {
    margin: 0;
    padding: 0;
    margin-bottom: 25px;
}

section.section-background-bright,
section.section-background-default,
section.section-background-dark,
{
    > .container
    {
        > .row
        {
            > div
            {
                > h1
                {
                    margin-top: -12px;

                    @media @tablet
                    {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.section-background-dark h1
{
  color: @red;
}

.section-background-dark {
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #fff;
    }
}

label
{
    a
    {
        color: @dark;
        text-decoration: underline;

        &:hover
        {
            color: @dark;
            text-decoration: none;
        }
    }
}

.marginbottom{
  margin-bottom: 24px;

    @media @tablet
    {
        margin-bottom: 40px;
    }
}

blockquote
{
    &.cite
    {
        p
        {
            margin-left: auto;
            max-width: 82%;
            display: inline-block;
            font-size: 17px;
            position: relative;
            color: @dark;
            font-weight: 700;
            font-style: italic;
            padding-right: 2px;


            @media @tablet
            {
                max-width: 230px;
            }

            @media @normal
            {
                max-width: 290px;
            }

            i
            {
                display: block;
                position: absolute;
                top: -22px;
                left: -40px;
                font-size: 48px;
                color: @red;

                @media @tablet
                {
                    font-size: 48px;
                    top: -22px;
                }

                @media @normal
                {
                    top: -35px;
                    left: -50px;
                    font-size: 72px;
                }
            }
        }
    }
}

.nopadding{
  padding-left:0;
  padding-right:0;
}

a {
  color: @medium;
}

a:hover {
  color: @medium;
  text-decoration:underline;
}

.clearfix:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
}

section.node-section {
  width: 100%;
  position: relative;
  margin: 0 auto;
    padding: 30px 0px;

    @media @tablet {
        padding: 64px 0px;
    }

    &.section-background-image
    {
        padding: 0px 0px 0px 0px;

        @media @tablet
        {
            padding: 36px 0 36px 0;
        }
    }

    &.view-mode-head_section {
        padding: 0px;
        position: relative;

        &:after {
            background: url(../images/header-shadow-bottom.png) repeat-x left bottom;
            bottom: 0px;
            content: "";
            display: block;
            height: 29px;
            left: 0px;
            position: absolute;
            width: 100%;
            z-index: 9;
        }
    }

  height: auto;
}

.inner {
  width: 960px;
  margin: 0 auto;
  position: relative;
  min-height: 50px;
  padding:30px 0;
  font-size: 18px;
  font-weight: 300;
}

.inner:after {
    content: "";
    display: table;
    clear: both;
}

.header
{
    .hideKabelLogo {
      display: none !important;
    }

    @media (max-width: 767px) {
        &:before {
            background: url(../images/vodafone-logo-white.svg) no-repeat 0px 0px;
            background-size: 35px 35px;
            content: "";
            display: block;
            height: 76px;
            left: 10px;
            top: 8px;
            position: absolute;
            width: 122px;
            z-index: 11;
        }
    }

    .logo_vodafone
    {
        display: none;
        width: 80px;
        height: auto;
        position: absolute;
        left: 0px;
        margin-left: 0px;
        top: 0px;

        @media @normal
        {
            display: block;
            top: 0px;
            width: 152px;
            height: 93px;
        }

        img
        {
            width: auto;
            height: 50px;
        }
    }

    #main-menu-navigation {
        ul li:last-child {
            @media @tablet {
                // background-color: @red;
                background-color: @gray-dark;
            }
        }
    }
}

#navbar.affix {
    @media (max-width: 767px) {
        .header {
              &:before {
                background: url(../images/vodafone-logo-white.svg) no-repeat 0px 0px;
                background-size: 35px 35px;
                content: "";
                display: block;
                height: 76px;
                left: 10px;
                top: 8px;
                position: absolute;
                width: 122px;
                z-index: 11;
            }
        }
    }

    &.affix
    {
        .logo_vodafone
        {
            display: none;
            width: 80px;
            height: auto;
            left: 0px;

            @media @normal
            {
                width: 89px;
            }
        }
    }
}

.header
{
    > .container
    {
        padding-left: 0px;
        padding-right: 0px;
        position: relative;

        @media @tablet {
          &:after {
            background: url(../images/vodafone-logo-white.svg) no-repeat right top;
            background-size: 35px 35px;
            content: " ";
            display: block;
            height: 70px;
            margin-left: -100%;
            position: absolute;
            left: 64px;
            top: 8px;
            visibility: visible;
            width: 100%;
          }
        }

      @media @normal {
        &:after {
          background-size: 50px 50px;
          top: 10px;
        }
      }

        @media @tablet
        {
            padding-right: inherit;
        }

        &:before {
          @media @normal {
                -webkit-box-shadow: 0 4px 10px -2px rgba(0,0,0,0.4);
              box-shadow: 0 4px 10px -2px rgba(0,0,0,0.4);
              content: " ";
              display: block;
              height: 70px;
              left: 50%;
              margin-left: -150em;
              position: absolute;
              width: 300em;
              top: 0px;
          }
        }
    }
/*
    &.affix {
        > .container {
            @media @normal {
                &:after {
                    content: " ";
                    display: block;
                    height: 70px;
                    margin-left: -100%;
                    position: absolute;
                    left: 64px;
                    top: 0px;
                    visibility: visible;
                    width: 100%;
                }
            }
        }
    }
*/
  width:100%;
  height: 50px;

    @media @tablet
    {
        height: 50px;
    }

    @media @normal
    {
        height: 70px;
    }

  z-index:1000;
  background: @red;

    .inner {
      padding:0;
      font-weight: 400;
      margin: 0 auto;
    }

    // ***********************
    // *** MAIN MENU Start ***
    // ***********************
    #main-menu-navigation
    {
        height: 100%;
    }

    .mainmenu
    {
        height: 50px;

        @media @pretablet {
            &:before {
                /* background-color: @red; */
                content: " ";
                display: block;
                height: 100%;
                position: absolute;
                left: -98em;
                width: 100em;
            }
        }

        @media @normal
        {
            height: 70px;
        }

        z-index: 1000;
        margin-left:-30px;
        position:relative;
        display:block;
        float:right;
    text-align: right;
    width: 100%;

        ul
        {
            list-style: none;
            height: 100%;

            li
            {
                border-bottom: 4px solid transparent;
                display: inline-block;
                height: 50px;
                margin: 0px;
                padding: 9px 0px 0px 0px;

                @media @normal
                {
                    height: 70px;
                    padding: 19px 0px 0px 0px;
                }

                &.last{
                    // .shadowed();
                    padding-left:5px;
                    padding-right:5px;
                }

                &.active, &:hover
                {
                    @media @tablet {
                        border-bottom: 4px solid @white;
                    }
                }

                a
                {
                    border-bottom: 2px solid transparent;
                    display: block;
                    float: left;
                    margin: 0px 0px 0px 0px;
                    text-decoration: none;
                    padding: 0px 10px 0px 10px;
                    text-align: center;
                    color: #fff;
                    font-size: 16px;
                    line-height: 32px;
                    outline: none !important;
                    font-family: 'vodafone', Arial, Verdana, sans-serif;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: @white;
                    }

                    &:focus, &::-moz-focus-inner {
                      border: 0;
                      outline: none !important;
                    }

                    &.important
                    {
                        color: @white;
                        font-weight: 700;

                        &:hover
                        {
                            border-bottom: none;
                            color: @white;
                        }
                    }
                }
            }
        }
    }

    &.affix-top .mainmenu {
        @media @pretablet {
            /* background: url(../images/arrow-normal.png) no-repeat 16px 0px; */
            height: 50px;
        }

        @media @normal {
            background-position: -2px 0px;
            height: 80px;
        }
    }

    &.affix .mainmenu {
        @media @pretablet {
            &:before {
                display: none;
            }
        }
    }

    // *********************
    // *** MAIN MENU END ***
    // *********************
}

// Blog article overwrites
body.node-type-blog-article .header .mainmenu:before,
body.blog_overview .header .mainmenu:before {
  background-color: transparent;
}

#navbar {
    &.affix {
        .header {
            > .container {
                @media @normal {
                    &:after {
                        //background: url(../images/logo_vodafone.jpg) no-repeat right top;
                    }
                }
            }
        }
    }
}

// ***************************
// *** COUNTER BOXES start ***
// ***************************
.counterbox
{
  background:#fff;
  box-shadow:0 0 12px rgba(0,0,0, 0.3);
  -webkit-box-shadow:0 0 12px rgba(0,0,0, 0.3);
  text-align:center;
  margin-bottom: 25px;
  padding-bottom:20px;

    @media @normal
    {
        margin-bottom: 90px;
    }

    .counter
    {
      background: @red;
      color:#fff;
      font-size: 40px;
      line-height: 57px;
      width: 100%;
      padding: 5px 10px;
      display: block;
      font-weight: bold;
    }

    .counter-arrow
    {
      background: url(img/counter_arrow.png);
      position: relative;
      margin: 0 auto;
      display: block;
      width: 213px;
      height: 18px;
    }

    h3
    {
      padding: 5px 40px;

        @media @tablet
        {
            padding: 5px 20px;
        }

      margin: 5px 0;
        font-size: 18px;
        line-height: 22px;
    }
}
// *************************
// *** COUNTER BOXES end ***
// *************************


// ***************************
// *** OWL INSIDER REPORTS ***
// ***************************
.view-insider-reports
{
    .view-content
    {
        @media @tablet
        {
            .make-row();
        }

        @media @normal
        {
            .make-row();
        }

        .owl-carousel
        {

            .make-xs-column(10);
            .make-xs-column-offset(1);
            .make-md-column(12);
            .make-md-column-offset(0);

            padding-left: 0px;
            padding-right: 0px;

            @media @tablet
            {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media @normal
            {
                width: 100%;
            }

            .owl-item
            {
                overflow: hidden;
                padding-bottom: 17px;

                @media @tablet
                {
                    overflow: visible;
                }

                .node-insider-report
                {
                    margin-left: 8px;
                    margin-right: 8px;

                    @media @tablet
                    {
                        margin-left: 0px;
                        margin-right: 0px;
                    }

                    &.node-teaser
                    {
                        .col-sm-6
                        {
                            @media @tablet
                            {
                                padding-left: 23px;
                            }

                            @media @normal
                            {
                                padding-left: 15px;
                            }

                            .field-name-field-image
                            {
                                position: relative;
                                display: block;
                                z-index: -100 !important;
                            }

                            .steckbrief
                            {
                                position: relative;
                                display: block;
                                z-index: 100 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// **************************
// *** SCHNELLTEST starts ***
// **************************
.nav > li > a:hover, .nav > li > a:focus {
  background-color: transparent;
}

#schnelltest,
.formresult
{
    padding-bottom: 38px;

    @media @normal
    {
        padding-bottom: 86px;
    }
}

#schnelltest
{

    fieldset {
        border:none;
        width:100%;
    }

    legend {
        font-size: 15px;
        padding: 7px 20px 4px 12px;

        @media @tablet
        {
            font-size:16px;
            padding: 3px 12px 3px 12px;
        }

        background:#fff;
        font-weight:300;
        color:#434b52;
        display:inline-block;
        width:auto;
        margin-bottom:5px;
    }

    legend
    {
        margin-bottom:15px;

        @media @normal
        {
            margin-bottom: 24px;
        }
    }

    #schnelltest h3{
        margin-top:10px;
    }

    label {
        display:block;
        margin: 15px 0 5px;

        @media @tablet
        {
            margin: 2px 0px;
        }
    }

    label + br
    {
        display: none;
    }

    input[type=text], input[type=password] {
        width:300px;
        padding:5px;
        border:solid 1px #ccc !important;
    }

    input[type=radio] {
        opacity: 0;
        position:relative;
        width:24px;
        height:24px;
        z-index: 9999;
    }
    /* default radio button style: unchecked */
    .radiooverlay {
        display: inline-block;
        position: relative;
        left: -24px; /* or whatever length you need here */
        height: 24px;
        width: 24px;
        background-color:#fff;
        border-radius: 50%;
        border:5px solid #ffcc00;
    }
    /* changed style when checked */
    input[type=radio]:checked + .radiooverlay {
        background-color: #ffcc00;
    }

    .radiotext
    {
        font-size: 15px;
        line-height: 20px;
        position:relative;
        top:-5px;
        left: -15px;

        @media @tablet
        {
            font-size: 16px;
            line-height: 24px;
            left: 0px;
        }

        font-weight:normal;
        color:#313030;
    }

    .schnelltest-btn{
        position:relative;
        margin-top:70px;
        height:40px;
    }
}


.submit-st {
    margin-top:-40px;
    display:block;
    z-index:3;
    position:relative;
}

.submit-st,
.prev,
.next {
    background-color:#fff;
    padding:0;
    color:#fff;
    height:40px;
    display:block;
    text-decoration:none;
    overflow:hidden;
    box-shadow:0 0 8px #999;
    -webkit-box-shadow:0 0 8px #999;
    border:0 none transparent;
}

.submit-st .linkname,
.prev .linkname,
.next .linkname
{
    text-transform: lowercase;
    height:100%;
    display:inline-block;
    background:#fff;
    color:#434b52;
    font-size: 18px;
    padding: 2px 20px;

    @media @tablet
    {
        font-size:20px;
        padding: 2px 40px;
    }

    font-weight:700;
    position: relative;
    top: -6px;
}

.submit-st,
#SaveAccount
{
    .linkname
    {
        text-transform: none;
    }
}

.submit-st i,
.prev i,
.next i{
    height:100%;
    width:40px;
    display:inline-block;
    background:#7d7d7d;
    color:#fff;
    text-align:center;
    padding:2px 2px;
    font-size:36px;
}

.submit-st i,
.next i{
    background:#ffcc00;
}

.submit-st:hover,
.prev:hover, .next:hover {
    text-decoration:none;
    cursor:pointer;
}

.prev:hover i{
    background:#ffcc00;
}

.next:hover i{
    color:#434b52;
}

.prev {
    float:left;
}

.submit-st,
.next {
    float:right;
}

.reset-schnelltest
{
    float: left;
}

 .stepwrap{
        position:absolute;
        bottom: 98px;

        @media @tablet
        {
            bottom: 75px;
        }

        @media @normal
        {
            bottom: 123px;
        }

        width:100%;
        left:0;
    }

 #steps {
    list-style:none;
    width:100%;
    overflow:hidden;
    position:relative;
    top:0;
    margin:0px;
    padding:0px;
    height:10px;
    background:#fff;

    @media @tablet
    {
        margin-bottom: 36px;
    }
 }

 #steps li {
    font-size:24px;
    float:left;
    padding:10px;
    color:#b0b1b3;
 }

 #steps li span {
    font-size:11px;
    display:block;
 }

 #steps li.current {
    color:#000;
 }

 #steps .filled{
    background:#7d7d7d;
 }

 .formresult{
    display:none;
 }

 @media (max-width: 767px) {
  .prev .linkname,
  .next .linkname{
        padding:2px 20px;
    }
}
// ************************
// *** SCHNELLTEST ends ***
// ************************


#headerimg{
  position:fixed;
}

.view-mode-head_section .bgimage{
    background-repeat: repeat !important;

    @media @tablet {
        background-size: contain;
    }
    width:100%;
}

.view-mode-head_section .bgimage-mobile {
    min-height: 230px;

    @media (min-width: 500px) {
        min-height: 400px;
    }
}

.view-mode-head_section .container{
  z-index:2;
  position:relative;
  display:block;
  padding-top:0;
  padding-bottom:0;
}

.parallaxwrapper ul {
  list-style:none;
  margin-left:35px;
}

.parallaxwrapper ul li:before{
  content:'\f00c';
  display:inline-block;
  position:relative;
  text-indent:-30px;
    font-size: 24px;
    line-height: 20px;
  top: 1px;

    @media @tablet
    {
      font-size: 36px;
      top:5px;
    }

  font-family: 'FontAwesome';
}

.parallaxwrapper ul li{
  color:@text-color;
  font-size: @font-size-base;
  line-height: @line-height-p;
    margin-bottom: 16px;

    @media @tablet
    {
        margin-bottom: 12px;
    }

    @media @normal
    {
      font-size: @font-size-base + 1;
      line-height: 22px;
        margin-bottom: 14px;
    }
}

.personenbeschreibung{
  position:absolute;
  bottom:0;

    @media @tablet
    {
      max-width: 186px;
    }
}

body
{
    &.page-node-18
    {
        .personenbeschreibung{
            @media @tablet
            {
              max-width: 280px;
            }
        }
    }
}

.personenbeschreibung p{
  color:#fff;
  margin: 0 0 15px 0;
  text-shadow:1px 1px 2px rgba(0, 0, 0, 0.8);
}

@media (max-width: 767px) {
  .view-mode-head_section ul {
    margin-left:32px;

        @media @tablet
        {
            margin-left:55px;
        }
  }
}

@media (max-width: 767px) {
  .view-mode-head_section .container {
    position:relative;
    width:auto!important;
  }

  .personenbeschreibung{
    position:fixed;
        top: 210px;

        @media @tablet
        {
            top:240px;
        }
  }
}

/*** SECTION 3 ***/
/*** OWL-CAROUSEL ***/

#owl-erfahrungsberichte .item img{
  display: block;
  width: 100%;
  height: auto;
}

.owl-stage-outer{
    margin-top: 30px;
  padding-bottom:10px;

    @media @tablet
    {
        margin-top: 0px;
    }
}

.owlarrows{
position: absolute;
  top:50%;
  margin-top:-40px;
  width:100%;
  left:0;

    @media @tablet {
      position:absolute;
      z-index: 1;
    }

    div
    {
        z-index: 1000;
    }
}

.owlarrows.affix-top,
.owlarrows.affix-bottom{
  position:absolute;
}

.owl-next{
    right:5px;
    @media @tablet
    {
        right:20px;
    }
  font-size:72px;
  color:@light;
  position:absolute;
}

.owl-prev{
    left:5px;
    @media @tablet
    {
        left:20px;
    }
  font-size:72px;
  color:@light;
  position:absolute;
}

@media (max-width: @screen-xs-max) {
  .owlarrows{
    top:35%;
  }

    .owlarrows.fixed {
        position: fixed;
    }

  .owl-next,
  .owl-prev{
    font-size:36px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .owl-next,
  .owl-prev{
  font-size:56px;
  }
}

.owl-next:hover,
.owl-prev:hover{
  cursor:pointer;
}

.owl-controls
{
    text-align: center;
  position:relative;
  display:block;
  width:100%;
  height:13px;
  width:auto;
  padding-left:15px;
  padding-right:15px;
  top: 0px;
  margin-left: -5px;

    @media @tablet
    {
        top: -70px;
        padding-left: 54%;
        text-align: left;
    }

    @media @normal
    {
        top: -140px;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.owl-dots{
  position:relative;
  display:block;
  height:13px;
  margin:0 auto;
}

.owl-dot {
  display:inline-block;
  margin:0 auto;
}

.owl-dot span{
  display:inline-block;
  background:@light;
  width:13px;
  height:13px;
  border-radius:50%;
  -webkit-border-radius:50%;
  margin:0 5px 0 5px;
}

.owl-dot.active span{
  background:@red;
}

.steckbrief{
  position:relative;
  float:right;
  margin-top:-110px;
  width:243px;
  margin-right:-15px;
  height:240px;
  background:url(img/bg_steckbrief.png) transparent no-repeat;
  padding:20px;
  -moz-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  -webkit-transform: rotate(8deg);
}

.owl-item .item .col-sm-6 h2{
  margin-top:0;
}

.steckbrief h3{
  margin-top:0;
}

.owl-zitat {
  margin-top: 20px;
    padding-left: 0px;

    @media @tablet
    {
        padding-left: 15px;
    }
}

.owl-zitat .right{
    padding-left: 0px;

    @media @tablet
    {
        padding-left: 15px;
    }

    @media @normal
    {
        padding-left: 0px;
    }

    p
    {
        text-align: right;
        color: @dark;
        font-size: 17px;
      font-weight:700;
      font-style:italic;
        padding-right: 2px;

        @media @tablet
        {
            font-size: @font-size-h3;
            padding-left: 15px;
        }
    }
}

.owl-zitat .left i
{
  color: @red;
  font-size:48px;
    position: relative;
    top: -3px;

    @media @tablet
    {
        font-size: 72px;
        position :absolute;
        top: 5px;
        right: 5px;
    }

  height:44px;
  line-height:50%;
  display:block;
}

.field-name-field-teaser-boxes
{
    padding-bottom: 18px;
}

.paragraphs-item-accordionbox {
  @media @tablet {
    margin-top: 30px;
  }
}

.view-id-tabcontents.view-display-id-block {
  .paragraphs-item-accordionbox {
    @media @tablet {
      margin-top: 0px;
    }
  }
}

@media (max-width: 992px) {

  .owl-stage-outer{
  margin-bottom:30px;
}

  .owl-item .item .col-sm-6 h2{
  margin-top:20px;
}

  .owl-zitat .right{
    font-size:17px;
  }

  .steckbrief{
    width:100%;
    height:auto;
    clear:both;
    float:none;
    margin-top:0;
    background-image:none;
    box-shadow:0 0 16px rgba(0,0,0,0.1);
    -webkit-box-shadow:0 0 16px rgba(0,0,0,0.1);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

@media (max-width: 767px) {
  #owl-erfahrungsberichte{
    width:83.333%;
    padding-top:30px;
  }

    .steckbrief{
    margin-bottom:40px;
  }

  .owl-controls{
      position:absolute;

      width:100%;
    }

  .owl-dots{

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #owl-erfahrungsberichte
    {
    width:88%;
  }

  .owl-next
    {
      right:10px;
    }

    .owl-prev
    {
      left:10px;
    }
}

@media (min-width: 992px) and (max-width: 1050px) {
  #owl-erfahrungsberichte{
    width:94%;
  }

    .owl-next{
      right:10px;
    }

    .owl-prev{
      left:10px;
    }
}


/*** SECTION 4 ***/
.acblock
{
  border-radius: 3px;
  margin-bottom: 20px;
  position: relative;
  background: #fff;

    @media @tablet
    {
        margin-bottom: 40px;
    }

    @media @normal
    {
        margin-bottom: 80px;
    }

    .acbtn,
    .achead
    {
        &.open
        {
          background: url(img/bg_white.jpg) repeat-y @red;
        }
    }

    .achead
    {
      background: url(img/bg_white.jpg) repeat-y @red;
      border-radius: 3px 3px 0px 0px;
      font-size: @font-size-h3;
      font-weight: 700;
      color: @dark;
        padding: 0px;

        @media @tablet
        {
            background-image: none;
            padding: 0 20px;
        }

        .acbtn
        {
          background: transparent;
          padding: 6px;
          font-size: 30px;
          width: 38px;
          text-align: center;
          position: relative;
          display: block;
          float: left;
          height: 100%;

            &.closed
            {
            background: #fff;

                .fa-angle-down
                {
                  display: none;
                }
            }

            &.open
            {
                .fa-angle-right{
                  display: none;
                }
            }

            i
            {
              display: block;
              height: 24px;
              margin-top: -17px;
              position: relative;
              top: 50%;
            }
        }

        h3
        {
          color: @white;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 15px;
          margin-left: 38px;
          display: block;
          position: relative;
          margin-bottom: 0px;

          @media @tablet
          {
            padding-left: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: auto;
          }
        }
    }

    .acbody
    {
      padding: 20px;
      display: none;

        @media @tablet
        {
          display: block;
        }

      color: @text-color;

        p
        {
          color: @text-color;
        }

        img
        {
          max-width: 100%;
          width: 100%;
          height: auto;
          margin: 0 auto;
          position: relative;
          display: block;
          margin-bottom: 20px;
        }
    }
}

.view-id-tabcontents.view-display-id-block {
  .acblock {
    @media @tablet {
      margin-bottom: 0px;
    }
  }
}

/*** SECTION 5 ***/

/* SECTIONS */
.sTop {
  min-height: 130px;
  background:#fff;
  color:#3d3d3d;
  padding: 50px 0;
  height: 300px;
}

/*** TOOLSTIPS ***/
#tooltip
{
    text-align: center;
    color: #fff;
    background: #111;
    position: absolute;
    z-index: 99999999;
    padding: 15px;
    font-size:12px;
}

#tooltip:after /* triangle decoration */
{
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #111;
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
}

#tooltip.top:after
{
    border-top-color: transparent;
    border-bottom: 10px solid #111;
    top: -20px;
    bottom: auto;
}

#tooltip.left:after
{
    left: 10px;
    margin: 0;
}

#tooltip.right:after
{
    right: 10px;
    left: auto;
    margin: 0;
}

// **************************
// *** TRANSIATIONS start ***
// **************************
@media @tablet
{
    .header {
        &.affix
        {
            .logo_vodafone
            {
                //.transition(0.5s, ease);
                //.transition-delay(0.5s);
            }
        }
    }

    // **************************
    // *** TRANSIATIONS end ***
    // **************************
}

.bgimage {
    position: relative;

    &:before {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;

        @media @pretablet {
            display: none;
        }

        @media @normal {
            display: none;
        }
    }

    .field-name-title {
        margin-bottom: 16px;
    }

    > .container {
        min-height: 230px;
        margin: 0;
        position: static;

        .parallaxwrapper {
            .headercol {
                p {
                    line-height: 22px;
                }

                strong {
                    color: @gray-dark;
                }

                .checklist {
                    margin-left: 0px;

                    li {
                        color: @white;
                        line-height: 30px;
                        margin-bottom: 0px;
                        padding-left: 32px;

                        &:before {
                          color: @white;
                          font-size: 16px;
                          left: 0px;
                          top: 0px;
                        }

                        &:first-child {
                            margin-top: 10px;
                        }

                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        @media (min-width: 700px) AND (max-width: 767px) {
            .parallaxwrapper {
                .headercol {
                    max-width: 60%;
                }
            }
        }

        @media (max-width: 699px) {
            .parallaxwrapper {
                .clearfix;
                background: transparent;

                .headercol {
                    padding-bottom: 0px;
                    padding-top: 18px;
                }

                .headercol + .headercol {
                    display: none;
                }

                h2, .h2 {
                    font-size: 44px;
                    line-height: 44px;
                }
            }
        }

        @media @pretablet {
            /* background: url(../images/arrow-normal.png) no-repeat left bottom; */
            min-height: 611px;
            max-width: 100%;
            margin: auto;
            padding-top: 22px;
            position: relative;
            left: 0;

            &:before {
                /* background-color: @red; */
                content: " ";
                display: block;
                height: 681px;
                position: absolute;
                left: -100em;
                top: -70px;
                width: 100em;
            }
        }
    }

    h2, .h2 {
        font-weight: @font-weight-bold;
    }

    h2, .h2 {
        color: @red;
      font-size: 60px;
    }

    p {
      color: @gray-dark;
    }

    .btn.btn-default {
      background-color: @violet;
      color: @white;
      border-radius: 4px;
      line-height: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @media @tablet {
        .field-name-title {
            h2 {
                line-height: 1;
            }

            margin-bottom: 16px;
        }

        > .container {
            padding-top: 16px;
        }

      .paragraphs-item-text-only {
        padding-top: 50px;
      }
    }

    @media @normal {
      .paragraphs-item-text-only {
        padding-top: 120px;
      }
    }


}

.btn-white {
    border-color: @white;
    color: @gray-dark;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 600;
    line-height: 46px;
    padding: 0px 18px;

    &:hover {

    }
}

.navbar-fixed-top, .navbar-fixed-bottom {
    z-index: 101;
}

.headercol {
    @media @normal {
        max-width: 400px;
    }
}

.custom-dropdown-ul {
    li {
      border-top: 1px solid @gray1;
      cursor: pointer;
      padding: 10px 15px;

      &:first-child {
        border-top: 0px;
      }

      &:hover {
        background-color: @gray1;
      }
    }
}

button.custom-dropdown {
    background-color: @gray1;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    height: auto;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    white-space: normal;

    .views-field-title {
      display: inline-block;

      @media @tablet {
        padding-right: 26px;
      }
    }

    span.fa {
      display: block;
      font-size: 22px;
      position: absolute;
      right: 16px;
      top: 10px;
    }
}

.view-id-tabcontents.view-display-id-block .paragraphs-item-text-only h3,
.view-id-tabcontents.view-display-id-block .paragraphs-item-text-only .h3 {
  text-align: center;
}

.number-content {
  margin-bottom: 30px;
  text-align: center;

  @media @tablet {
    margin-bottom: 0px;
  }

  .number {
    background-color: #fff;
    border-radius: 50%;
    color: @red;
    font-size: 30px;
    font-weight: bold;
    height: 50px;
    left: 50%;
    line-height: 50px;
    margin-bottom: 20px;
    margin-left: -25px;
    position: relative;
    width: 50px;
  }

  .h4 {
    color: @red;
    font-size: 20px;
    font-weight: bold;
  }
}

div:first-child {
    &.number-content {
        margin-top: 30px;

        @media @tablet {
            margin-top: 0px;
        }
    }
}

.content-facts {
    text-align: center;

    .content-facts-number {
        color: @red;
        font-family: 'vodafone';
        font-size: 30px;
        line-height: 24px;
    }

    .content-facts-text {
        border-bottom: 1px solid @gray3;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 20px;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 12px;
        padding-top: 12px;

        @media @tablet {
            border-bottom: 0px;
            border-top: 1px solid @gray3;
            margin-top: 12px;
        }
    }
}

.paragraphs-item-accordionbox a {
    color: @red;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
