@footer-height: 40px;


/*** FOOTER ***/
footer{
    position:   relative;
    z-index:    999;

    .footer {
        background: @gray-dark;

        .pane-content {
            p,
            .footermenu,
            .footermenu li {
                display: inline-block;
                margin: 0;
                font-size: 12px;
                line-height: 18px;

                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            p,
            .footermenu {
                padding: ((40px - 18px) / 2) 0;
            }

            p {
                color: @gray4;
            }


            .footermenu {
                display: block;

                li {
                    display: block;

                    & > i:first-child {
                        margin-right: 5px;
                    }
                }

                &, * {
                    color: @white;
                }

                @media @tablet
                {
                    text-align: right;

                    li {
                        display: inline-block;
                        padding: 0 20px;

                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }


    .info-wrap
    {
        position:   fixed;
        width:      100%;
        bottom:     25px;
        z-index:    201;

        .btn {
            box-shadow: 0 0 10px rgba( 0, 0, 0, 0.15);
        }
    }
}
